import React from "react"
import { Link } from "gatsby"

const MenuList = () => {
  return (
    <ul>
      <li>
        <Link to="/a-propos/" activeClassName="active">
          À Propos
        </Link>
      </li>
      <li>
        <Link to="/massotherapie/" activeClassName="active">
          Massothérapie
        </Link>
      </li>
      <li>
        <Link to="/services/" activeClassName="active">
          Services
        </Link>
      </li>
      <li>
        <Link to="/offrir-un-soin/" activeClassName="active">
          Offrir un Soin
        </Link>
      </li>
      <li>
        <Link to="/tarifs/" activeClassName="active">
          Tarifs
        </Link>
      </li>
      <li>
        <Link to="/contact/" activeClassName="active">
          Contact
        </Link>
      </li>
    </ul>
  )
}

export default MenuList
