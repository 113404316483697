import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import BookingContext from "../context/BookingContext"
import Header from "./header"
import SideNav from "./sidenav"
import FloatingBtn from "./floatingbtn"
import CtaOverlay from "./ctaoverlay"
import ContactInfo from "./contactinfo"
import Copyright from "./copyright"

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${({ open, show }) => (open || show ? "hidden" : "visible")};
  }
`

const Layout = ({ children }) => {
  const [sideNavActive, setSideNavActive] = useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <BookingContext.Consumer>
      {booking => (
        <>
          <GlobalStyle open={booking.show} show={sideNavActive} />
          <Header
            siteTitle={data.site.siteMetadata.title}
            show={sideNavActive}
            setSideNavActive={setSideNavActive}
          />
          <SideNav show={sideNavActive} setSideNavActive={setSideNavActive} />
          <FloatingBtn />
          <CtaOverlay />
          <main>{children}</main>
          <footer>
            <ContactInfo />
            <Copyright />
          </footer>
        </>
      )}
    </BookingContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
