import React from "react"
import styled from "styled-components"
import BookingContext from "../context/BookingContext"

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10;
  transform: ${({ show }) => (show ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  h3 {
    text-align: center;
    color: var(--white);
  }
  p {
    color: var(--heading);
    font-size: 0.75rem;
  }
  .close-btn-mobile {
    display: none;
  }
  @media (max-width: 640px) {
    display: block;
    overflow-y: auto;
    padding: var(-small);
    .close-btn {
      display: none;
    }
    .close-btn-mobile {
      display: block;
      text-align: right;
      padding-bottom: var(--small);
      svg {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
      }
    }
  }
`

const Form = styled.div`
  background: var(--heading);
  padding: var(--xl);
  width: 60vw;
  max-width: 800px;
  @media (max-width: 640px) {
    padding: var(--medium) var(--large);
    margin: 0;
    width: 100%;
  }
  input {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: var(--small);
    margin: var(--small) 0;
    border: 0;
  }
  input::placeholder,
  textarea::placeholder {
    color: var(--black);
    opacity: 1;
    text-transform: uppercase;
  }
  input[type="submit"] {
    background: var(--button);
    color: var(--white);
    border: 1px solid var(--button);
    font-weight: 700;
    padding: var(--medium);
    cursor: pointer;
    -webkit-appearance: none;
  }
  textarea {
    width: 100%;
    font-size: 1rem;
    padding: var(--small);
  }
`

const CtaOverlay = () => {
  return (
    <BookingContext.Consumer>
      {booking => (
        <Overlay show={booking.show} className="vertical-center">
          <div className="container">
            <div
              className="close-btn"
              onClick={() => booking.toggleShow()}
              onKeyDown={() => booking.toggleShow()}
              role="button"
              tabIndex={0}
            >
              <svg
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.48438 3.32812L6.09375 0.546875H8.20312L5.61719 4.71094L8.28906 9H6.19531L4.50781 6.10938L2.82812 9H0.71875L3.39062 4.71094L0.8125 0.546875H2.90625L4.48438 3.32812Z"
                  fill="#044C69"
                />
              </svg>
            </div>
            <Form>
              <form
                id="reservationform"
                name="reservationform"
                method="POST"
                action="https://formsubmit.io/send/2fc1942b-e70b-472b-a277-5e9fb5a3a883"
              >
                <div className="close-btn-mobile" tabIndex={0}>
                  <svg
                    onClick={() => booking.toggleShow()}
                    onKeyDown={() => booking.toggleShow()}
                    role="button"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.48438 3.32812L6.09375 0.546875H8.20312L5.61719 4.71094L8.28906 9H6.19531L4.50781 6.10938L2.82812 9H0.71875L3.39062 4.71094L0.8125 0.546875H2.90625L4.48438 3.32812Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <h3>Formulaire</h3>
                <input
                  name="_redirect"
                  type="hidden"
                  id="name"
                  value="https://elsamassotherapie.ch/merci"
                />
                <input
                  type="text"
                  placeholder="NOM / PRÉNOM*"
                  name="Nom"
                  required={true}
                />
                <input
                  type="tel"
                  placeholder="TÉLÉPHONE*"
                  name="Téléphone"
                  required={true}
                />
                <input
                  type="email"
                  placeholder="E-MAIL*"
                  name="Email"
                  required={true}
                />
                <span className="custom-dropdown">
                  <select name="Service" required={true}>
                    <option value="noservice">Choisir le service...</option>
                    <option value="massages">Massages</option>
                    <option value="soinsvisage">Soins du Visage</option>
                    <option value="soinscorps">Soins du Corps</option>
                  </select>
                </span>
                <input
                  type="text"
                  placeholder="Jour et Heure"
                  name="JourHeure"
                  required={true}
                />
                <textarea placeholder="MESSAGE" name="Message" rows={5} />
                <input
                  name="_formsubmit_id"
                  type="text"
                  style={{ display: "none" }}
                />
                <input type="submit" value="ENVOYER" />
              </form>
            </Form>
          </div>
        </Overlay>
      )}
    </BookingContext.Consumer>
  )
}

export default CtaOverlay
