import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import MenuList from "../components/menulist"
import Image from "../components/tacticalImg"

const HeaderSection = styled.header`
  padding: var(--medium) 0;
  background: var(--white);

  .menu-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    button {
      text-align: center;
      padding: var(--small);
      background: none;
      border: 0;
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    @media (min-width: 769px) {
      button {
        display: none;
      }
    }
  }
  @media (max-width: 640px) {
    .col-md-3 {
      grid-column: span 6;
    }
    .col-md-1 {
      display: none;
    }
    .col-md-8 {
      grid-column: span 6;
    }
  }
`

const Menu = styled.nav`
  height: 100%;
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul li {
    text-transform: uppercase;
  }
  ul li a {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--heading);
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const Header = ({ siteTitle, show, setSideNavActive }) => {
  return (
    <HeaderSection>
      <div className="container grid side-padding">
        <div className="col-md-3">
          <Link to="/">
            <Image src="logo.png" alt={`${siteTitle} logo`} />
          </Link>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-8 vertical-center">
          <Menu>
            <MenuList />
          </Menu>
          <div className="menu-btn">
            <button onClick={() => setSideNavActive(!show)}>
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#044c69"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </HeaderSection>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
