import React from "react"
import styled from "styled-components"
import MenuList from "../components/menulist"

const SideBar = styled.aside`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 5;
  overflow-y: auto;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
  background: var(--heading);
  nav {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    text-align: center;
    li {
      margin: var(--large);
    }
    a {
      font-size: 1.5rem;
      color: var(--white);
      text-transform: uppercase;
    }
    a:hover {
      border-bottom: 2px solid var(--white);
    }
    .active {
      border-bottom: 2px solid var(--white);
    }
  }
  .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`

const SideNav = ({ show, setSideNavActive }) => (
  <SideBar show={show}>
    <nav>
      <div
        className="close-btn"
        role="button"
        tabIndex={0}
        onClick={() => setSideNavActive(!show)}
      >
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.48438 3.32812L6.09375 0.546875H8.20312L5.61719 4.71094L8.28906 9H6.19531L4.50781 6.10938L2.82812 9H0.71875L3.39062 4.71094L0.8125 0.546875H2.90625L4.48438 3.32812Z"
            fill="#fff"
          />
        </svg>
      </div>
      <MenuList />
    </nav>
  </SideBar>
)

export default SideNav
