import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function renderImage(file, text, height) {
  return <Img fluid={file.node.childImageSharp.fluid} alt={text} />
}
const TacticalImg = function (props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { extension: { regex: "/jpg|png/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find(image => image.node.relativePath === props.src),
          props.alt
        )
      }
    />
  )
}
export default TacticalImg
