import React from "react"
import styled from "styled-components"
import { Location } from "@reach/router"
import BookingContext from "../context/BookingContext"

const Button = styled.button`
  border: 1px solid var(--booking);
  position: fixed;
  top: 200px;
  right: 0;
  background: var(--booking);
  padding: var(--small) var(--medium);
  margin: 0;
  cursor: pointer;
  z-index: 3;
  transition: ease-in-out 0.3s all;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  &:hover {
    background: #eaa500;
  }
`

const FloatingBtn = () => {
  return (
    <BookingContext.Consumer>
      {booking => (
        <>
          <Location>
            {({ location }) => {
              if (
                location.pathname !== "/contact/" &&
                location.pathname !== "/offrir-un-soin/"
              ) {
                return (
                  <Button onClick={() => booking.toggleShow()}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.432 1.8H14.4V0.2C14.4 0.09 14.3136 0 14.208 0H12.864C12.7584 0 12.672 0.09 12.672 0.2V1.8H6.528V0.2C6.528 0.09 6.4416 0 6.336 0H4.992C4.8864 0 4.8 0.09 4.8 0.2V1.8H0.768C0.3432 1.8 0 2.1575 0 2.6V19.2C0 19.6425 0.3432 20 0.768 20H18.432C18.8568 20 19.2 19.6425 19.2 19.2V2.6C19.2 2.1575 18.8568 1.8 18.432 1.8ZM17.472 18.2H1.728V8.7H17.472V18.2ZM1.728 7V3.6H4.8V4.8C4.8 4.91 4.8864 5 4.992 5H6.336C6.4416 5 6.528 4.91 6.528 4.8V3.6H12.672V4.8C12.672 4.91 12.7584 5 12.864 5H14.208C14.3136 5 14.4 4.91 14.4 4.8V3.6H17.472V7H1.728Z"
                        fill="#FBF6F0"
                      />
                    </svg>
                  </Button>
                )
              }
            }}
          </Location>
        </>
      )}
    </BookingContext.Consumer>
  )
}

export default FloatingBtn
