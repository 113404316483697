import React from "react"
import styled from "styled-components"

const CopyrightBlock = styled.div`
  font-size: 0.75rem;
  text-align: center;
  padding: var(--small) 0;
`

const Copyright = () => {
  return (
    <CopyrightBlock className="container">
      Elsa Massothérapie & Bien-être {new Date().getFullYear()}
    </CopyrightBlock>
  )
}

export default Copyright
